import React from 'react'
import { AsH3, EyebrowMD, HeadingMD } from '@/atoms/Text'
import { slugs } from '@/constants'
import {
  formatTitleRating,
  formatTitleMoviePosterPath,
  formatTitleCrewMap,
  CatalogTitle,
  CatalogMovie,
  CatalogSeries,
  formatTitleReleaseYear,
  formatTitleFriendlyReleaseDate,
  formatTitleDuration,
  isSeries,
  formatTitleLikeVerticalImagePath,
} from '@/services/ContentCatalog'
import { FilmDetails as FilmDetailsType } from '@/types/codegen-contentful'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { CastRail } from '@/views/WatchTheatricalView/FilmDetails/CastRail'
import { DescriptorSection } from '@/views/WatchTheatricalView/FilmDetails/DescriptorSection'
import { CrewCredits } from './CrewCredits'

interface FilmDetailsComponentProps {
  catalogTitle: CatalogMovie | CatalogSeries
  filmDetails: FilmDetailsType
  slug: string
  projectType: string
}
export function FilmDetails(props: FilmDetailsComponentProps) {
  const { t } = useTranslate('theatrical-presales')
  const { locale } = useLocale()

  const moviePosterPath =
    formatTitleMoviePosterPath(props.catalogTitle) ?? formatTitleLikeVerticalImagePath(props.catalogTitle)
  const rating = formatTitleRating(props.catalogTitle)
  const releaseYear = formatTitleReleaseYear(props.catalogTitle)
  const duration = formatTitleDuration(props.catalogTitle)
  const releaseDate = formatReleaseDate(locale, props.catalogTitle, props.filmDetails)
  const notYetRatedText = t('notYetRated', 'Not Yet Rated')

  return (
    <div className="grid grid-cols-1 gap-[32px] text-left lg:grid-cols-2">
      {moviePosterPath && (
        <div className="hidden lg:block">
          <img
            src={getCloudinaryImageUrl({ path: moviePosterPath, width: 638, height: 930 })}
            className="ml-auto"
            alt={t('moviePosterAlt', '{{name}} Movie Poster', { name: props.catalogTitle.title })}
            height={930}
            width={638}
          />
        </div>
      )}
      <div key="details" className="px-8 py-12 lg:max-w-[700px]">
        {props.catalogTitle && (
          <>
            <HeadingMD weight="bold" color="white" as={AsH3} className="lg:photon-heading-lg mb-4">
              {props.catalogTitle.title}
            </HeadingMD>
            <div className="mb-4 flex gap-2 lg:mb-6 lg:gap-4">
              {rating && (
                <div className="w-fit whitespace-nowrap rounded-full border-[1px] border-white px-4 py-1">
                  <EyebrowMD color="white" weight="bold">
                    {props.slug === slugs.kingOfKings ? notYetRatedText : formatTitleRating(props.catalogTitle)}
                  </EyebrowMD>
                </div>
              )}
              {releaseYear && (
                <div className="w-fit whitespace-nowrap rounded-full border-[1px] border-white px-4 py-1">
                  <EyebrowMD color="white" weight="bold">
                    {releaseYear}
                  </EyebrowMD>
                </div>
              )}
              {duration && (
                <div className="w-fit whitespace-nowrap rounded-full border-[1px] border-white px-4 py-1">
                  <EyebrowMD color="white" weight="bold">
                    {duration}
                  </EyebrowMD>
                </div>
              )}
            </div>
            <div className="mb-10">
              <DescriptorSection
                title={
                  isSeries(props.catalogTitle)
                    ? t('seriesSynopsis', 'Series Synopsis')
                    : t('filmSynopsis', 'Film synopsis')
                }
                description={
                  props.catalogTitle.description.long ??
                  props.catalogTitle.description.medium ??
                  props.catalogTitle.description.short
                }
              />
            </div>
          </>
        )}
        <div className="mb-8 grid grid-cols-1 gap-[32px] lg:grid-cols-2">
          {props.catalogTitle && releaseDate && (
            <DescriptorSection title={t('releaseDate', 'Release Date')} description={releaseDate} />
          )}
          <CrewSections catalogTitle={props.catalogTitle} />
        </div>
        <div>
          <CastRail catalogTitle={props.catalogTitle} />
        </div>
      </div>
    </div>
  )
}

export function formatReleaseDate(
  locale: string,
  catalogTitle: CatalogTitle,
  filmDetails: FilmDetailsType,
): string | undefined {
  if (filmDetails?.theatricalReleaseDateOverrideText) return filmDetails.theatricalReleaseDateOverrideText
  return formatTitleFriendlyReleaseDate(locale, catalogTitle)
}

export function CrewSections(props: { catalogTitle?: CatalogTitle }) {
  const { t } = useTranslate('theatrical-presales')
  if (!props.catalogTitle) return null

  const crew = formatTitleCrewMap(props.catalogTitle)

  const sections = [
    {
      title: t('filmDirectorV2', {
        count: crew['Director'].length,
        defaultValue: 'Director',
        defaultValue_other: 'Directors',
      }),
      names: crew['Director'],
    },
    {
      title: t('executiveProducerV2', {
        count: crew['Executive Producer'].length,
        defaultValue: 'Executive Producer',
        defaultValue_other: 'Executive Producers',
      }),
      names: crew['Executive Producer'],
    },
    {
      title: t('producerV2', {
        count: crew['Producer'].length,
        defaultValue: 'Producer',
        defaultValue_other: 'Producers',
      }),
      names: crew['Producer'],
    },
    {
      title: t('writer', {
        count: crew['Writer'].length,
        defaultValue: 'Writer',
        defaultValue_other: 'Writers',
      }),
      names: crew['Writer'],
    },
    {
      title: t('directorOfPhotography', {
        count: crew['Director of Photography'].length,
        defaultValue: 'Director of Photography',
        defaultValue_other: 'Directors of Photography',
      }),
      names: crew['Director of Photography'],
    },
    {
      title: t('distributor', {
        count: crew['Distributor'].length,
        defaultValue: 'Distributor',
        defaultValue_other: 'Distributors',
      }),
      names: crew['Distributor'],
    },
  ]

  return sections.map((section) => <CrewCredits key={section.title} names={section.names} title={section.title} />)
}
