import React from 'react'
import classNames from 'classnames'
import { ProjectFundingGoalWidget } from '@/organisms/ProjectFundingGoalDisplay/ProjectFundingGoalWidget'
import { useGuildMember } from '@/services/GuildUserService'
import { GetTheatricalPreReleasePageDataResult } from '@/services/TheatricalPreReleaseService'
import { buildCloudinaryImagePathFromContentfulObject } from '@/utils/Cloudinary/cloudinary'
import { GetInvolvedSquare } from '@/views/WatchTheatricalView/GetInvolvedSquare'
import { useWatchTheatricalContext } from '@/views/WatchTheatricalView/WatchTheatricalContext'

type CallToAction = NonNullable<
  NonNullable<GetTheatricalPreReleasePageDataResult['ctaSquaresCollection']>['items'][number]
>

export const CtaSquareCollection: React.FC = () => {
  const { ctaSquaresCollection, crowdfundingSlug, slug } = useWatchTheatricalContext()
  const { isGuildMember } = useGuildMember()

  const ctas = filterToApplicableCtas((ctaSquaresCollection?.items as CallToAction[] | null) || [], isGuildMember)

  if (ctas.length <= 0) return null

  return (
    <>
      <div
        id="get-involved"
        className={classNames('grid grid-cols-1', formatColumnClassName(formatNumColumns(ctas.length)))}
      >
        {ctas.map((cta) => {
          return (
            <GetInvolvedSquare
              key={cta.title}
              backgroundImg={buildCloudinaryImagePathFromContentfulObject(cta.backgroundImage)}
              icon={buildCloudinaryImagePathFromContentfulObject(cta.icon)}
              title={cta.title}
              description={cta.description}
              buttonText={isGuildMember ? cta?.guildButtonText ?? cta.buttonText : cta.buttonText}
              buttonHref={isGuildMember ? cta?.guildButtonHref ?? cta.buttonHref : cta.buttonHref}
              emailListId={cta.emailListId}
              slug={slug}
              type={cta.type}
              className={formatCtaClassName(formatNumColumns(ctas.length))}
            />
          )
        })}
      </div>
      {crowdfundingSlug && (
        <ProjectFundingGoalWidget
          className="mx-auto max-w-3xl px-10 md:px-5 lg:mt-8 xl:max-w-5xl"
          crowdfundingSlug={crowdfundingSlug}
          isDarkMode
        />
      )}
    </>
  )
}

export function formatNumColumns(numItems: number): number {
  if (numItems > 4) return 3
  if (numItems % 3 === 0) return 3
  if (numItems % 2 === 0) return 2
  return 1
}

export function formatColumnClassName(numColumns: number): string {
  return ['grid-cols-1', 'md:grid-cols-2', 'md:grid-cols-3'][numColumns - 1]
}

export function formatCtaClassName(numColumns: number): string {
  return numColumns == 1 || numColumns % 3 === 0 ? 'md:aspect-h-1 md:aspect-w-1' : 'md:aspect-h-2 md:aspect-w-3'
}

function filterToApplicableCtas(ctas: CallToAction[], isGuildMember: boolean): CallToAction[] {
  if (!ctas) return []

  return ctas.filter(Boolean).filter(needsGuildJoinCta.bind(null, isGuildMember))
}

function needsGuildJoinCta(isGuildMember: boolean, cta: CallToAction): boolean {
  if (cta.marketingFocus === 'join-the-guild') return !isGuildMember
  return true
}
